import { Component, Input, SecurityContext } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "@abstract/BaseComponent";
import { ImageLocal } from "../quickUploadPod.interface";
import { Subscription } from "rxjs";
import { QuickUploadPhotoService } from "@app/admin/components/quick-upload-photos/quickUploadPhoto.service";

@Component({
  selector: "quick-upload-pod",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class QuickUploadPod extends BaseComponent {
  constructor(
    protected activatedRoute: ActivatedRoute,
    private quickUploadPhotoService: QuickUploadPhotoService
  ) {
    super();
  }

  @Input() children: any;

  public isConverting: boolean = false;
  public images: ImageLocal[] = [];
  public selectedImageIndex: number;
  public subscription: Subscription;
  public formInfoWidth: number = 50; //percent
  
  ngOnInit(): void {
    super.ngOnInit();
    this.autoShrinkSideBar();
    this.subscription.add(this.quickUploadPhotoService.images.subscribe({
      next: images => {
        this.images = images;
      }
    }));
    this.subscription.add(this.quickUploadPhotoService.imageSelectedIndex.subscribe({
      next: index => {
        this.selectedImageIndex = index;
      }
    }));
    this.subscription.add(this.quickUploadPhotoService.formInfoWidth.subscribe({
      next: value => {
        this.formInfoWidth = value;
      }
    }));
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public showShipmentWarpIds(image: ImageLocal) {
    let string = this.showShipmentWarpId(image.addedToShipments?.[0]?.warpId);
    if (image.addedToShipments?.length > 1) {
      string += ` +${image.addedToShipments?.length - 1}`
    }
    return string;
  }
}

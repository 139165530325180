import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Utils } from "@services/utils";
import { environment } from "@env/environment";
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: "gopuff-billing-statement",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class GoPuffBillingStatementComponent extends BaseComponent {
    constructor() {
        super();
    }

    @Input() public terms: string = '7'

    public downloading = false;
    public fromDate: Date
    public toDate: Date

    private padding(n: number) {
        if (n > 9) return n.toString()
        else return '0' + n.toString()
    }

    get dateMode(): string {
        if (this.terms === '7') return 'week'
        else return 'month'
    }

    public getNextDay(date: Date): Date {
        return new Date(date.getTime() + 24 * 3600 * 1000);
    }

    public onBtnExport() {
        this.downloading = true;
        const month = this.fromDate.getMonth() + 1

        const endDate = this.getNextDay(this.toDate)
        const toMonth = endDate.getMonth() + 1
        
        const date = `${this.fromDate.getFullYear()}-${this.padding(month)}-${this.padding(this.fromDate.getDate())}`
        const toDate = `${endDate.getFullYear()}-${this.padding(toMonth)}-${this.padding(endDate.getDate())}`
        
        const params = {
            startDate: date,
            endDate: toDate
        }

        let url = `${environment.supportUrl}/gopuff/invoice/generate`;
        this.api.postDownload(url, params, {observe: 'response', responseType: 'arraybuffer'}).subscribe(
            (resp: HttpResponse<any>) => {
                let body = resp.body;
                const contentDisposition = resp.headers.get('Content-Disposition');
                const fileName = Utils.getFileNameFromContentDisposition(contentDisposition)  ?? `GoPuff-${date}-${(Date.now() / 1000).toFixed(0)}.xlsx`;
                const fileType = resp.headers.get('Content-Type')
                let blob = new Blob([body], { type: fileType });
                let url = URL.createObjectURL(blob);
                Utils.downloadFile(url, fileName);
                this.downloading = false
            }, err => {
                this.showErr(err);
                this.downloading = false
            }
      );
  }

}

import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Utils } from "@services/utils";
import { environment } from "@env/environment";
import { HttpResponse } from "@angular/common/http";
import moment from "moment";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: "gopuff-billing-statement-v2",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class GoPuffBillingStatementV2Component extends BaseComponent {

    public filter = {
        clientIds: null,
        fromDate: null,
        toDate: null,
    }
    public downloading = false;

    clientOptions = [
        { id: '01HRZEJV34B82ZVT2B1TE06VWX', name: 'Gopuff' },
        { id: '01J2CW1K337KRCZCV8NPS19VSC', name: 'Gopuff (Bevmo!)' },
        { id: '01JARNHT4T8R1QPZ1DEV0WBBYH', name: 'Bevmo (Store Delivery)' },
    ]
    
    get needUpdate() {
        return !!this.filter.clientIds && !!this.filter.toDate && !!this.filter.fromDate;
    }

    public onBtnExport() {
        if (!this.needUpdate) {
            this.showWarning('', 'Please select client and date range');
            return;
        }
        this.downloading = true;
        const data: any = {
            clientIds: this.filter.clientIds,
        }
        if (this.filter.fromDate) {
          const fromDate = DateUtil.toBeginOfDay(this.filter.fromDate);
          data.fromDate = DateUtil.convertLocalTime(fromDate, 'America/New_York')?.toISOString();
        }
        if (this.filter.toDate) {
          const toDate = DateUtil.toEndOfDay(this.filter.toDate);
          data.toDate = DateUtil.convertLocalTime(toDate, 'America/New_York')?.toISOString();
        }
        const date = DateUtil.dateToString(this.filter.fromDate, 'YYYY-MM-DD');
        
        let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/gopuff-billing-statement`;
        this.api.postDownload(url, data, {observe: 'response', responseType: 'arraybuffer', timeout: 20 * 60 * 1000}).subscribe(
            (resp: HttpResponse<any>) => {
                let body = resp.body;
                const contentDisposition = resp.headers.get('Content-Disposition');
                const fileName = Utils.getFileNameFromContentDisposition(contentDisposition)  ?? `GoPuff-${date}-${(Date.now() / 1000).toFixed(0)}.xlsx`;
                const fileType = resp.headers.get('Content-Type')
                let blob = new Blob([body], { type: fileType });
                let url = URL.createObjectURL(blob);
                Utils.downloadFile(url, fileName);
                this.downloading = false
            },
            err => {
                this.showErr(err);
                this.downloading = false
            }
        )
    }

}

import { Component, ElementRef, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Log } from "@services/log";
import { DialogService } from "@dialogs/dialog.service";
import { ExportLinehaulPaymentValidate } from "./validate-dlg";

@Component({
  selector: "ic-linehaul-report",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class IcLinehaulPayment extends BaseFormItem {

  @ViewChild('inputFile') inputFile: ElementRef<HTMLInputElement>;
  
  constructor(
  ) {
    super();
  }

  loading: boolean;

  protected formGroupDeclaration: FormGroupDeclaration = {
    loadCode: {label: 'Load code', required: true, placeHolder: 'Enter load code'},
    linehaulManifestFile: {label: 'Linehaul Manifest file', required: true, type: 'uploadFile'},
  };

  public get labelSelectFile(): string {
    let key = 'linehaulManifestFile';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : null;
  }

  public onExport() {
    let params = this.getFormData(true);
    if (!params) {
      return Log.e("getFormData must be overridden");
    }
    const backendUrl = environment.backendUrlWithoutCDN || environment.backendUrl;
    let url = `${backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-linehaul-payment-validate`;
    this.loading = true;
    this.api.postFormData(url, params, { observe: "response" }).subscribe(
      (resp) => {
        let body = resp.body;
        if (body && body.data?.list_data?.length) {
          this.openDialogValidate(body.data.list_data);
        }
        this.loading = false;
      },
      (err) => {
        this.onCreateFailure(err);
        this.loading = false;
      }
    )
  }

  private onBtnRetry() {
    let params = this.getFormData(true);
    if (!params) {
      return Log.e("getFormData must be overridden");
    }
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-linehaul-payment-validate`;
    return this.api.postFormData(url, params, { observe: "response" });
  }

  private openDialogValidate(data) {
    DialogService.openDialog1(ExportLinehaulPaymentValidate, {
      nzMaskClosable: false,
      nzComponentParams: {
        shipments: data,
        loadCode: this.getItemValue('loadCode'),
        onRetry: () => { return this.onBtnRetry() } ,
        onClose: () => {
          this.fileToUpload = {};
          this.formInput.get('linehaulManifestFile')?.updateValueAndValidity();
          this.inputFile.nativeElement.value = "";
          this.setItemValue('loadCode', '');
        }
      },
      nzClassName: "modal-xxl",
    });
  }

}

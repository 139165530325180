import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'import-keywords',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ImportKeywordsComponent {
  keywords: string = '';

  constructor(
    private modalRef: NzModalRef
  ) {}

  onOk() {
    // Parse keywords theo line hoặc dấu phẩy
    const parsedKeywords = this.keywords
      .split(/[\n,]+/) // Split theo line hoặc dấu phẩy
      .map(keyword => keyword.trim()) // Loại bỏ khoảng trắng thừa
      .filter(keyword => keyword.length > 0); // Loại bỏ các dòng trống

    this.modalRef.close(parsedKeywords);
  }

  onCancel() {
    this.modalRef.close();
  }
} 
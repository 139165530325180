import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ImageLocal, OrderDisplayInfo } from "./quickUploadPod.interface";
import { Shipment } from "@wearewarp/types/data-model";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { jsPDF } from "jspdf"; //sử dụng để tạo PDF
import * as pdfjsLib from "pdfjs-dist"; //sử dụng để đọc file PDF
import { QuickUploadPhotoService } from "../components/quick-upload-photos/quickUploadPhoto.service";
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";

@Injectable({
  providedIn: 'root'
})
export class QuickUploadPodService {
  public activeTabIndex: BehaviorSubject<number> = new BehaviorSubject(0);
  public tabs: BehaviorSubject<any[]> = new BehaviorSubject([
    {
      name: 'Search',
      closable: false,
      type: "search"
    },
    // {
    //   name: 'Search AI',
    //   closable: false,
    //   type: "search-ai"
    // },
  ]);


  constructor(private api: ApiService, private quickUploadPhotoService: QuickUploadPhotoService) {

  }
  

  openOrderDetail(order: OrderDisplayInfo) {
    const indexOfExistTab = this.tabs.getValue().findIndex(tab => tab.type === "order-detail" && tab.data.orderId === order.orderId);
    if (indexOfExistTab !== -1) {
      this.activeTabIndex.next(indexOfExistTab);
      return;
    }
    let name = order.newWarpId;
    if (order.shipmentCodes.split(',').length == 1 && order.shipmentCodes.split(',')[0].length > 0) {
      name = order.shipmentCodes.split(',')[0];
    }
    this.tabs.next([...this.tabs.getValue(), {
      name: name,
      closable: true,
      type: "order-detail",
      data: {
        orderId: order.orderId
      }
    }]);
    this.activeTabIndex.next(this.tabs.getValue().length - 1);
  }

  closeTabByIndex(index: number) {
    this.tabs.next(this.tabs.getValue().filter((_, i) => i !== index));
  }

  setActiveTab(index: number) {
    this.activeTabIndex.next(index);
  }

 
  public async getShipments({ keyword }: { keyword: string }) {
    if (!keyword) return [];
    // https://gw.wearewarp.com/api/v2/orders?skip=0&limit=10&search=1407821
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_ORDERS)}/?search=${keyword}`).toPromise();
    return result?.data?.list_data || []
  }

  public async getOrderDetail(orderId: string) {
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_ORDERS)}/${orderId}`).toPromise();
    return result?.data;
  }

  public async getAllChildrens(shipmentId: string) {
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipmentId}/all-childrens`).toPromise();
    return result?.data?.list_data || []
  }


  private async getDataBeforeUploadPOD(shipment: Shipment) {
    const jobId = shipment.lastJobId
    if (!jobId) {
      throw new Error("Shipment has not been routed yet.")
    }
    const jobResponse = await this.api.GET(`${Const.APIV2(Const.APIURI_JOBS)}/${jobId}`).toPromise();
    if (!jobResponse.data) {
      throw new Error("Job not found.")
    }
    const tasks = jobResponse?.data?.tasks;
    const shipmentDropoffTask = tasks.find(task =>
      task.type === Const.TaskType.DROPOFF
      && task.shipmentId === shipment.id
    );
    if (!shipmentDropoffTask) {
      throw new Error("Shipment dropoff task not found.")
    }
    return {
      jobId,
      shipmentDropoffTask
    }
  }

  public async addImagesToShipment(images: ImageLocal[], shipment: Shipment) {
    const { jobId, shipmentDropoffTask } = await this.getDataBeforeUploadPOD(shipment);

    let fileInfo = {
      blob: null,
      name: null,
    }
    if (images.length > 1) {
      //convert images to pdf
      const pdfBlob = await this.quickUploadPhotoService.convertImagesToPdf(images);
      fileInfo.blob = pdfBlob;
      fileInfo.name = `${images[0].name || 'image'}.pdf`;
    }
    else {
      fileInfo.blob = images[0].blob;
      fileInfo.name = images[0].name;
    }
    //add POD
    let apiUrl = `${Const.APIV2(Const.APIURI_TASKS)}/uploadPodForListTasksOfStop`;
    let formData = new FormData();
    const jsonData = {
      'taskIds': [shipmentDropoffTask.id],
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadPOD", new File([fileInfo.blob], fileInfo.name || 'image.jpg'));
    const result = await this.api.postFormData(apiUrl, formData).toPromise();
   
    await this.confirmPOD(result.data?.uploadInfo?.podItem, [shipmentDropoffTask.id]);

    images.map(image => {
      image.addedToShipments = [...(image.addedToShipments || []), shipment];
    });
    this.quickUploadPhotoService.images.next(this.quickUploadPhotoService.images.getValue());
    return result;
  }

  async confirmPOD(podFileId: string, taskIds: string[]) {
    let params = {
      taskIds: taskIds,
      "uploadPodFileId": podFileId
    };
    let url = `${Const.APIV2(Const.APIURI_TASKS)}/confirmPodForListTasksOfStop`;
    const result = await this.api.POST(url, params).toPromise();
    return result;
  }
}
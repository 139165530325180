<div class="container-box flex">
    <div class="bottom15 right10">
        <div class="bottom5">From Date</div>
        <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="From Date" [(ngModel)]="fromDate">
        </nz-date-picker>        
    </div>
    <div class="bottom15 right10">
        <div class="bottom5">To Date</div>
        <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="From Date" [(ngModel)]="toDate">
        </nz-date-picker>        
    </div>
    <div class="actions">
        <div class="bottom5">Download</div>
        <button nz-button [disabled]="!fromDate" (click)="onBtnExport()" nzType="primary" [nzLoading]="downloading">Export</button>
    </div>    
</div>

import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { Observable } from "rxjs";

@Component({
  selector: '[export-linehaul-payment-validate]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class ExportLinehaulPaymentValidate extends BaseDialog {

  listOfData: any[] = [];
  isValidPallets: boolean = false;
  isValidInvoice: boolean = false;
  isAllInvoiceCreated: boolean = false;
  isDownloadingInvoice: boolean = false;
  isDownloadingPallets: boolean = false;
  isLoading: boolean = false;

  @Input() onRetry: () => Observable<any>;
  onClose: () => void;
  @Input() set shipments(value) {
    if (!Utils.isArrayNotEmpty(value)) return;
    this.onProcessData(value);
  }
  @Input() loadCode: string;

  private onProcessData(value) {
    let listOfData = value;
    let isValidPallets = true;
    let isValidInvoice = true;
    let isAllInvoiceCreated = true;
    for (let item of listOfData) {
      item.isValidNumOfpallets = item.pallets == item.numOfPalletsImport;
      if (!item.isValidNumOfpallets) isValidPallets = false;
      if (!item.isValidGenInvoice?.isValid) isValidInvoice = false;
      if (!item.isValidGenInvoice?.hasInvoice) isAllInvoiceCreated = false;
      if (item.isValidGenInvoice?.orderId) {
        item.orderUrl = [this.routeAdminOrderList, item.isValidGenInvoice.orderId];
      }
    }
    this.isValidPallets = isValidPallets;
    this.isValidInvoice = isValidInvoice;
    this.isAllInvoiceCreated = isAllInvoiceCreated;
    this.listOfData = listOfData;
  }
  
  ngOnInit(): void {
    super.ngOnInit();
  }

  get labelBtnInvoice() {
    return this.isAllInvoiceCreated ? 'Download Invoice' : 'Generate Invoice';
  }

  onBtnReCheck() {
    this.isLoading = true;
    this.onRetry().subscribe(
      resp => {
        let body = resp.body;
        if (body && body.data?.list_data?.length) {
          this.onProcessData(body.data.list_data);
        }
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  onBtnDownloadExcel() {
    if (!this.isValidPallets) return;
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-linehaul-payment-manifest`;
    const params = {
      filter: JSON.stringify({
        loadCode: this.loadCode,
      })
    }
    url = Utils.appendQueryStringIntoUrl(url, params);
    this.isDownloadingPallets = true;
    this.api.download(url).subscribe(
      (resp) => {
        let blob = new Blob([resp], { type: "application/vnd.ms-excel" });
        let url = URL.createObjectURL(blob);
        let fileName = `Sak_s_Invoice_${this.loadCode}-${DateUtil.format( Date.now(), 'YYMMDD-hhmmss')}.xlsx`;
        Utils.downloadFile(url, fileName);
        this.isDownloadingPallets = false;
        this.onBtnReCheck();
      },
      (err) => {
        this.showErr(err);
        this.isDownloadingPallets = false;
      }
    );
  }

  onBtnGenerateInvoice() {
    if (!this.isValidPallets || !this.isValidInvoice) return;
    const backendUrl = environment.backendUrlWithoutCDN || environment.backendUrl;
    let url = `${backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-linehaul-payment-invoice`;
    const params = {
      filter: JSON.stringify({
        loadCode: this.loadCode,
      })
    }
    url = Utils.appendQueryStringIntoUrl(url, params);
    this.isDownloadingInvoice = true;
    this.api.GET(url, { responseType: 'arraybuffer', timeout: 5 * 60 * 1000 }, 5 * 60 * 1000).subscribe(
      (resp) => {
        let blob = new Blob([resp], { type: "application/zip" });
        let url = URL.createObjectURL(blob);
        let fileName = `Sak_s_Invoice_${this.loadCode}-${DateUtil.format( Date.now(), 'YYMMDD-hhmmss')}.zip`;
        Utils.downloadFile(url, fileName);
        this.isDownloadingInvoice = false;
      },
      (err) => {
        this.showErr(err);
        this.isDownloadingInvoice = false;
      }
    );
  }

  public closeDialog() {
    this.onClose();
    super.closeDialog();
  }
    
}

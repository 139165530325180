<div class="container-box">
    <div class="filter">
        <nz-row [nzGutter]="[8,8]">
            <nz-col [nzXs]="24">
                <nz-select nzBackdrop="true" nzAllowClear [(ngModel)]="filter.clientIds" nzMode="multiple"
                    nzDropdownClassName="dispatch-filter-customer" style="width: 100%;">
                    <nz-option *ngFor="let item of clientOptions" 
                        [nzValue]="item.id" [nzLabel]="item.name">
                    </nz-option>
                </nz-select>
            </nz-col>
            <nz-col [nzXs]="12">
                <div class="bottom5">From Date</div>
                <nz-input-group nzAddOnAfter="EST">
                    <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="From Date" [(ngModel)]="filter.fromDate">
                    </nz-date-picker>
                </nz-input-group>
            </nz-col>
            <nz-col [nzXs]="12">
                <div class="bottom5">To Date</div>
                <nz-input-group nzAddOnAfter="EST">
                    <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="To Date" [(ngModel)]="filter.toDate">
                    </nz-date-picker>
                </nz-input-group>
            </nz-col>
        </nz-row>
    </div>
    <div class="actions">
        <button nz-button [disabled]="!needUpdate" (click)="onBtnExport()" nzType="primary" [nzLoading]="downloading">Export</button>
    </div>
</div>


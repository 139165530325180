<div class="container-box">
  <nz-tabset nzHideAdd nzType="editable-card" nzSize="small" style="height:100%;"
    [(nzSelectedIndex)]="selectedTab" (nzClose)="onTabClose($event)" (nzSelectChange)="onTabChange($event)">
      <nz-tab nzSize="small" nzTitle="Crossdock">
        <ng-template nz-tab>
          <crossdock-form></crossdock-form>
        </ng-template>
      </nz-tab>
      <nz-tab nzSize="small" nzTitle="Paid Status">
        <ng-template nz-tab>
          <paid-status-form></paid-status-form>
        </ng-template>
      </nz-tab>
  </nz-tabset>

  <div class="actions">
    <button nz-button class="item" (click)="onClickAction('toggleWidth')" nz-tooltip nzTooltipTitle="Resize Form"
      nzTooltipPlacement="left">
      <span nz-icon [nzType]="'swap'" nzTheme="outline"></span>
    </button>
  </div>
</div>
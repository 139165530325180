import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";

@Injectable({
  providedIn: 'root'
})
export class QuickSearchService {
  
  constructor(private api: ApiService) {

  }
  
  public async searchRoute({ keyword }: { keyword: string }) {
    if (!keyword) return [];
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_JOBS)}/search-by-code?code=${keyword}`).toPromise();
    return result?.data?.list_data || []
  }

  public async searchShipment({ keyword }: { keyword: string }) {
    if (!keyword) return [];
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/search-by-code?code=${keyword}`).toPromise();
    return result?.data?.list_data || []
  }

  public async getAllShipmentChildrens(shipmentId: string) {
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipmentId}/all-childrens`).toPromise();
    return result?.data?.list_data || []
  }

  public async searchDenimJob({ keyword }: { keyword: string }) {
    if (!keyword) return [];
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_FINANCES)}/statement/check-denim-job-exist?query=${keyword}`).toPromise();
    return result?.data?.list_data || []
  }
}
import { BaseList } from "../../base/list";
import { Component, SecurityContext } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '@services/api.service';
import { BaseComponent } from "@abstract/BaseComponent";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ImageLocal } from "../../components/quick-upload-photos/quickUploadPhoto.interface";
import { Subscription } from "rxjs";

@Component({
  selector: "reports",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class Reports extends BaseComponent {
  constructor(
    protected activatedRoute: ActivatedRoute,
  ) {
    super();
  }
}

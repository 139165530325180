<div class="container-box">
  <div class="content-box" #contentBox>
    <div class="table-shipment">
      <nz-table #tableShipment [nzData]="routes" nzSize="small" [nzLoading]="loading" [nzShowPagination]="false"
        [nzFrontPagination]="false" [nzScroll]="{ y: (contentHeight - 95) + 'px' }">
        <thead>
          <tr>
            <th nzWidth="120px">Keyword</th>
            <th nzWidth="100px">Route</th>
            <th>Cost</th>
            <th>Carrier</th>
            <th>Paid</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tableShipment.data; let index = index; trackBy: trackByKeyword"
            [class.highlighted-row]="data.keyword === highlightedKeyword">
            <td class="cell-clickable">
              <p nz-typography nzEditable nzEditTooltip="click to edit" [(nzContent)]="data.keyword"
                (nzContentChange)="onKeywordChange(index)"></p>
            </td>
            <td class="cell-clickable text-center"
              [ngClass]="{'text-danger': data.status === 'error', 'text-warning': data.status === 'no-route'}"
              (click)="onViewRouteDetail(data)"><span>
                <b>{{data.routeCode }}</b>
              </span>
              <span *ngIf="data.status === 'success'">
                <nz-tag nzColor="green" nzSize="small" *ngIf="data.routeStatus === 'completed'">Completed</nz-tag>
                <nz-tag nzColor="blue" nzSize="small" *ngIf="data.routeStatus === 'inProgress'">In Progress</nz-tag>
                <nz-tag nzColor="default" nzSize="small" *ngIf="data.routeStatus === 'created'">Created</nz-tag>
                <nz-tag nzColor="red" nzSize="small" *ngIf="data.routeStatus === 'canceled'">Canceled</nz-tag>
              </span>
            </td>
            <td class="text-right">
              <ng-container *ngIf="data.costEditing">
                <nz-input-group [nzSuffix]="suffixIconSave">
                  <input type="text" nz-input placeholder="edit cost" [(ngModel)]="data.cost"
                    (keyup.enter)="onCostChange(index)" />
                </nz-input-group>
                <ng-template #suffixIconSave>
                  <span nz-icon nzType="save" nzTheme="outline" color="green" (click)="onCostChange(index)"></span>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="!data.costEditing">
                {{formatCost(data.cost)}}
                <i nz-icon nzType="edit" nzTheme="outline" (click)="onCostEditing(index)"></i>
              </ng-container>
            </td>
            <td class="text-left">{{data.carrier}}</td>
            <td class="text-left">
              <span *ngIf="data.status === 'loading'">
                <i nz-icon nzType="loading" nzSpin nzTheme="outline" nzSize="large" style="font-size: 16px;"></i>
              </span>
              <span *ngIf="data.status === 'error'">
                <i nz-icon nzType="close-circle" style="color: red; font-size: 16px;" nzTheme="outline"></i>
              </span>
              <span *ngIf="data.status === 'manual'">
                <nz-tag nzColor="purple" nzSize="small">Manual</nz-tag>
              </span>
              <div *ngIf="data.status === 'success'">
                <ng-container *ngIf="data.paidDate">
                  <b class="paid-date" [ngClass]="{'text-success': data.paid}" nz-tooltip
                    [nzTooltipTitle]="data.paid ? 'Paid on ' + formatDate(data.paidDate) : 'Due to pay on ' + formatDate(data.paidDate)">
                    {{formatDate(data.paidDate)}}
                  </b>
                  <br>
                  <ng-container *ngIf="data.denimJobLink">
                    <a href="{{data.denimJobLink}}" class="denim-job-link" target="_blank"><span>{{data.paidNote}}</span></a>
                    <i nz-icon nzType="check-circle" nzTheme="outline" style="color: green; font-size: 16px;"
                      *ngIf="data.denimVerified.includes('Verified')" nz-tooltip
                      [nzTooltipTitle]="data.denimVerified"></i>
                    <i nz-icon nzType="close-circle" nzTheme="outline" style="color: red; font-size: 16px;"
                      *ngIf="!data.denimVerified.includes('Verified')" nz-tooltip
                      [nzTooltipTitle]="data.denimVerified"></i>
                  </ng-container>
                  <span *ngIf="!data.denimJobLink">{{data.paidNote}}</span>
                </ng-container>
                <ng-container *ngIf="!data.paidDate">
                  <nz-tag nzColor="red" nzSize="small">Unpaid</nz-tag>
                </ng-container>
              </div>
            </td>
            <td class="text-center">
              <button nz-button nzType="link" (click)="onSearch(index)">
                <i nz-icon nzType="reload" color="blue" nzTheme="outline"></i>
              </button>
              <button nz-button nzType="link" nzDanger nz-popconfirm
                [nzPopconfirmTitle]="'Are you sure to delete this route?'" (nzOnConfirm)="onDeleteRoute(data)">
                <i nz-icon nzType="delete" nzTheme="outline"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div class="footer">
      <div class="left-side">
        <div class="total-cost">
          <span>Total Cost: </span>
          <span>{{getTotalCost()}}</span>
        </div>
        <div class="data-count">
          <span>Data Count: </span>
          <span>{{routes.length}}</span>
        </div>
      </div>
      <div class="right-side">
        <button nz-button nz-tooltip [nzTooltipTitle]="'Import Keywords'" (click)="onImportKeywords()">
          <i nz-icon nzType="download" nzTheme="outline"></i>
        </button>
        <button nz-button nz-tooltip [nzTooltipTitle]="'Export to CSV'" (click)="onExportToExcel()">
          <i nz-icon nzType="upload" nzTheme="outline"></i>
        </button>

        <button nz-button nzDanger nz-tooltip [nzTooltipTitle]="'Reset all data'" nz-popconfirm
          [nzPopconfirmTitle]="'Are you sure to reset all data?'" (nzOnConfirm)="onResetAllData()">
          <i nz-icon nzType="reload" nzTheme="outline"></i>
        </button>
      </div>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuickUploadPod } from './quick-upload-pod';
import { Reports } from './reports';
import { AssignCarriersProvidePod } from './carriers-provide-pod';
import { QuickSearchRoute } from './quick-search-route';


const financeRoutes: Routes = [
  {
    path: 'quick-upload-pod', component: QuickUploadPod, data: { title: 'Quick Upload POD - WARP Admin' }
  },
  {
    path: 'quick-search-route', component: QuickSearchRoute, data: { title: 'Quick Search Route - WARP Admin' }
  },
  {
    path: 'provide-pod', component: AssignCarriersProvidePod, data: { title: 'Request POD - WARP Admin'}
  },
  {
    path: 'reports', component: Reports, data: { title: 'Reports - WARP Admin' },
  },
  {
    path: 'non-factored', loadChildren: () => import('./denim/module').then(m => m.DenimModule), data: { title: 'Denim - WARP Admin' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(financeRoutes)],
  exports: [RouterModule]
})
export class FinanceRoutingModule { }

<div class="container-box">
    <nz-row [nzGutter]="[8,8]">
        <nz-col [nzXs]="12">
            <div class="bottom15">
                <div class="bottom5">Date</div>
                <nz-input-group nzAddOnAfter="EST">
                    <nz-date-picker 
                        class="width-100" 
                        nzFormat="yyyy-MM-dd" 
                        nzPlaceHolder="From Date" 
                        [(ngModel)]="fromDate"
                    >
                    </nz-date-picker>
                </nz-input-group>
            </div>
        </nz-col>
    </nz-row>

    <div class="actions">
        <button nz-button 
            [disabled]="!fromDate" 
            (click)="onBtnExport()" nzType="primary"
            [nzLoading]="downloading"
        >Download</button>
    </div>
</div>
import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";;
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";
import { ApiService } from "@services/api.service";

@Component({
  selector: "download-daily-route",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class DownloadDailyRouteComponent extends BaseComponent {
    constructor() {
        super();
    }

    public downloading = false;
    public fromDate: Date

    public onBtnExport() {
        this.downloading = true;
        let fromDate = this.fromDate;
        let toDate = new Date(fromDate.getTime());
        fromDate = DateUtil.toBeginOfDay(fromDate);
        toDate = DateUtil.toEndOfDay(toDate);

        const params = {
            isDownload: true,
            fromDate: DateUtil.convertLocalTime(fromDate, 'America/New_York')?.toISOString(),
            toDate: DateUtil.convertLocalTime(toDate, 'America/New_York')?.toISOString(),
        }

        let url = `${environment.backendUrl}/v2/jobs/download-daily-routes`;
        this.api.postExport(url, params).subscribe(
            resp => {
                ApiService.handleDownloadResponse(resp);
                this.downloading = false;
            }, err => {
                this.showErr(err);
                this.downloading = false;
            }
        );
  }
}

import { Component, ChangeDetectorRef, ViewChild, ElementRef } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { QuickUploadPhotoService } from "../../../components/quick-upload-photos/quickUploadPhoto.service";
import { DeliveryInfo, Job, Order, Shipment } from "@wearewarp/types/data-model";
import { WarpId } from "@wearewarp/universal-libs";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { OrderDisplayInfo } from "../../../components/quick-upload-photos/quickUploadPhoto.interface";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { QuickSearchService } from "../../quickSearch.service";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import moment from "moment";
import { ImportKeywordsComponent } from "../import-keywords";

interface RouteDisplayInfo {
  keyword: string;
  routeId?: string;
  routeCode?: string;
  cost?: string;
  carrier?: string;
  status?: 'loading' | 'error' | 'success' | 'no-route' | 'manual';
  routeStatus?: string;
  costEditing?: boolean;
}

@Component({
  selector: "crossdock-form",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class CrossdockRouteForm extends BaseComponent {
  constructor(
    private quickUploadPhotoService: QuickUploadPhotoService,
    private cdr: ChangeDetectorRef,
    private quickSearchService: QuickSearchService
  ) {
    super();
  }

  @ViewChild('contentBox') contentBox!: ElementRef;
  @ViewChild('tableShipment') tableShipment!: any;

  public loading: boolean = false;
  public routes: RouteDisplayInfo[] = [];
  public expandSet = new Set<string>();
  public contentHeight: number = 0;
  public highlightedKeyword: string = '';

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.quickUploadPhotoService.searchText.subscribe({
      next: keyword => {
        this.onAdd(keyword)
      }
    }));
  }

  onWindowResize() {
    const height = this.contentBox.nativeElement.offsetHeight;
    this.contentHeight = height;
  }

  ngAfterViewInit() {
    this.onWindowResize();
    this.cdr.detectChanges();
    super.ngAfterViewInit();
  }

  onKeywordChange(index: number) {
    this.onSearch(index);
  }

  onCostChange(index: number) {
    this.routes[index].status = 'manual';
    this.routes[index].routeStatus = 'manual';
    this.routes[index].costEditing = false;
    //refresh view
    this.routes = [...this.routes];
  }

  onAdd(keyword: string) {
    if (!keyword) return;
    //nếu keyword đã tồn tại thì không thêm và scroll đến row đó
    const existingRoute = this.routes.find(route => route.keyword === keyword);
    if (existingRoute) {
      this.highlightedKeyword = keyword;
      this.scrollToRow(keyword);
      this.showErr("Keyword already exists");
      return;
    }

    this.routes = [
      ...this.routes,
      {
        keyword,
        status: 'loading',
      }
    ];
    this.onSearch(this.routes.length - 1);
    // Scroll to bottom after adding new data
    setTimeout(() => {
      this.scrollToBottom();
    });
  }

  scrollToBottom() {
    // Lấy element có class ant-table-body từ nz-table
    const tableBody = this.contentBox.nativeElement.querySelector('.ant-table-body');
    if (tableBody) {
      tableBody.scrollTop = tableBody.scrollHeight;
    }
  }

  scrollToRow(keyword: string) {
    const tableBody = this.contentBox.nativeElement.querySelector('.ant-table-body');
    if (tableBody) {
      const rows = tableBody.querySelectorAll('tr');
      const targetRow: any = Array.from(rows).find((row: any) => {
        const keywordCell = row.querySelector('td:first-child');
        return keywordCell?.textContent?.trim() === keyword;
      });
      
      if (targetRow) {
        // Tính toán vị trí scroll
        const rowTop = targetRow.offsetTop;
        const rowHeight = targetRow.offsetHeight;
        const tableHeight = tableBody.clientHeight;
        
        // Scroll để row nằm ở giữa table với animation
        const targetScrollTop = rowTop - (tableHeight / 2) + (rowHeight / 2);
        const startScrollTop = tableBody.scrollTop;
        const distance = targetScrollTop - startScrollTop;
        const duration = 300; // 300ms
        const startTime = performance.now();

        const animateScroll = (currentTime: number) => {
          const elapsed = currentTime - startTime;
          const progress = Math.min(elapsed / duration, 1);

          // Easing function (easeInOutQuad)
          const easeProgress = progress < 0.5
            ? 2 * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 2) / 2;

          tableBody.scrollTop = startScrollTop + (distance * easeProgress);

          if (progress < 1) {
            requestAnimationFrame(animateScroll);
          }
        };

        requestAnimationFrame(animateScroll);
      }
    }
  }

  onViewRouteDetail(route: RouteDisplayInfo) {
    if (route.status === 'success') {
      window.open(`${Const.routeAdminDispatchList}/${route.routeId}`, '_blank');
    }
    if (route.status === 'no-route') {
      window.open(`${Const.routeAdminOrderList}/${route.routeId}`, '_blank');
    }
  }

  onDeleteRoute(route: RouteDisplayInfo) {
    //delete route
    this.routes = this.routes.filter(r => r.keyword !== route.keyword);
    this.routes = [...this.routes];
  }

  trackByKeyword(index: number, item: RouteDisplayInfo): string {
    return item.keyword;
  }

  getTotalCost() {
    let totalCost = this.routes.reduce((acc, route) => {
      let cost = route.cost ? parseFloat(route.cost.replace('$', '')) : 0;
      if (isNaN(cost)) cost = 0;
      return acc + cost;
    }, 0);
    return this.formatCost(totalCost);
  }

  async onSearch(routeIndex: number) {
    const keyword = this.routes[routeIndex].keyword;
    this.routes[routeIndex].status = 'loading';
    let result: Job[] = await this.quickSearchService.searchRoute({ keyword });


    if (result.length === 0) {
      //check nếu keyword là shipment code
      const shipment = await this.onSearchShipment(keyword);
      if (!shipment) {
        this.routes[routeIndex].routeCode = 'Not found';
        this.routes[routeIndex].status = 'error';
        this.routes[routeIndex].cost = 'N/A';
        this.routes[routeIndex].carrier = 'N/A';
      }
      else if (!shipment.lastJobId) {
        this.routes[routeIndex].routeCode = 'No route';
        this.routes[routeIndex].routeId = shipment.orderId;
        this.routes[routeIndex].status = 'no-route';
      }
      else {
        //tìm route theo shipment vừa tìm được
        result = await this.quickSearchService.searchRoute({ keyword: shipment.warpId });
      }
    }

    //update route on view
    if (result.length > 0) {
      this.routes[routeIndex] = {
        ...this.routes[routeIndex],
        routeId: result[0].id,
        routeCode: result[0].code,
        cost: result[0]?.assignedCarrier?.cost?.grandTotal ? result[0]?.assignedCarrier?.cost?.grandTotal + "" : 'N/A',
        carrier: result[0].carrier.basicInfo.name || 'N/A',
        routeStatus: result[0].status,
        status: 'success',
      }
    }
    //refresh view
    this.routes = [...this.routes];
  }

  async onSearchShipment(keyword: string) {
    const result: Shipment[] = await this.quickSearchService.searchShipment({ keyword });
    if (result.length === 0) {
      return;
    }
    const shipment = result?.[0];
    //check nếu shipment là crossdock
    if (await this.isCrossdock(shipment)) {
      return shipment
    }
    const childrens = await this.quickSearchService.getAllShipmentChildrens(shipment.id);
    for (const child of childrens) {
      if (await this.isCrossdock(child)) {
        child.orderId = shipment.orderId;
        return child;
      }
    }
    return;
  }

  async isCrossdock(shipment: Shipment) {
    if (shipment.shipmentTransitType === Const.ShipmentTransitType.layover) {
      return true;
    }
    if (shipment.shipmentType == Const.ShipmentTypes.storage) {
      return true;
    }
    const pickup = shipment.deliveryInfos?.find(info => info.type === Const.TaskType.PICKUP);
    const delivery = shipment.deliveryInfos?.find(info => info.type === Const.TaskType.DROPOFF);

    //same address
    if (pickup.warehouseId === delivery.warehouseId) {
      return true;
    }
    if (Utils.isSameAddress(pickup.addr, delivery.addr)) {
      return true;
    }
    return false;
  }

  formatCost(cost: number) {
    return InputHelper.formatMoney2(cost, '$');
  }

  onCostEditing(index: number) {
    this.routes[index].costEditing = true;
    this.routes = [...this.routes];
  }

  onExportToExcel() {
    const data = this.routes.map(route => ({
      keyword: route.keyword,
      routeCode: route.routeCode,
      cost: route.cost,
      carrier: route.carrier,
      status: route.status,
    }));
    const excelData = [
      {
        keyword: "Keyword",
        routeCode: "Route Code",
        cost: "Cost",
        carrier: "Carrier",
        status: "Status",
      },
      ...data
    ];
    //export to CSV
    const csv = excelData.map(row => Object.values(row).join(',')).join('\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `crossdock-${moment().format('YYYY-MM-DD-HH.mm.ss')}.csv`;
    link.click();
  }

  onResetAllData() {
    this.routes = [];
    this.routes = [...this.routes];
  }

  onImportKeywords() {
    //open modal import keywords
    this.modalService.create({
      nzTitle: 'Import Keywords',
      nzContent: ImportKeywordsComponent,
      nzOnOk: (componentInstance: ImportKeywordsComponent) => {
        return componentInstance.onOk();
      },
      nzOnCancel: (componentInstance: ImportKeywordsComponent) => {
        return componentInstance.onCancel();
      }
    }).afterClose.subscribe((keywords: string[]) => {
      if (keywords) {
        // Thêm từng keyword vào routes
        keywords.forEach(keyword => {
          this.onAdd(keyword);
        });
      }
    });
  }
}

<div class="import-keywords">
  <nz-form-item>
    <nz-form-control>
      <textarea 
        nz-input 
        [(ngModel)]="keywords"
        [nzAutosize]="{ minRows: 3, maxRows: 15 }"
        placeholder="Nhập keywords, mỗi keyword một dòng hoặc phân tách bằng dấu phẩy">
      </textarea>
    </nz-form-control>
  </nz-form-item>
</div> 